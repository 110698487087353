
.header-top {
  height: 100px;
  background-color: #f4f4f4;
  border-bottom: 1px solid #b1b3b3;

  p {

    display: inline-block;
    margin-top: 5px;
    color: black;

  }

}

//.header-top {
//  display: block;
//  height: 131px;
//  background-color: #f4f4f4;
//  border-bottom: 1px solid #b1b3b3;
//}

.logo {
  position: relative;
  float: left;
  img {
    height: 61px;
    margin-right: 20px;
    width: auto;
  }

}

.logo1 {
  float: left;
  padding-top: 39px;
  padding-bottom: 31px;
  margin-top: 0;
  p {
    display: inline-block;
  }
}

.btn-primary {
  background-color: #f26334;
  &:hover {
    background-color: #f26384;
  }
}

.secondary-nav {
  display: block;
  float: right;
  margin-top: 35px;
  text-align: right;
  font-size: 12px;
}

.navigation {
  background-color: #fff;
}

.homepage-contents {
  background-color: #f4f4f4;
}

footer {
  background-color: #f4f4f4;
}