.inv_container {
  margin: 10px;
}
.inv_title {
  text-align: center;
  color:black;
  font-size: smaller;
}

.inv_address_container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.inv_receiver {
  text-align: center;
  .note_rec {
    padding-top: 20px;
  }
}
.inv_address {
  color:black;
  font-size: smaller;
  white-space: pre-line;
}
.inv_acc_name {
  margin-top: 20px;
  .note {
    padding-left: 30px;
  }
}
.inv_date {
  text-align: center;
  color:black;
  font-size: smaller;
}

.inv_table {
  margin-top: 20px;
  thead {
    background-color: lightgray;
  }
}
.inv_footer {
  text-align: center;
  color:black;
  font-size: smaller;
}
